<template>
  <div class="login">
    <img width="100%" height="100%" src="videop.png" alt="" />
    <img class="bg" :src="require('@/assets/images/false.png')" />
    <div class="mian-con">
      <div class="">
        <img class="loginbg" :src="require('@/assets/images/loginbg.png')" />
        <div @click="handleSubmit" class="login-box">
          <img class="loginBox" :src="require('@/assets/images/loginw.png')" />
          <span v-if="!Loading" class="loading-t">立即登录</span>
          <div v-if="Loading" class="loading-con">
            <a-spin size="large" />
          </div>
        </div>

        <div class="logincard-con">
          <div class="input-con">
            <img :src="require('@/assets/images/zhangh.png')" />
            <h1 class="line"></h1>
            <input v-model="userName" placeholder="账号" type="text" />
          </div>
          <div class="input-con">
            <img :src="require('@/assets/images/mima.png')" />
            <h1 class="line"></h1>
            <input
              v-model="password"
              placeholder="密码"
              type="password"
              id="demo_input"
              name="password"
            />
            <a-icon
              v-if="!showpass"
              @click="hideShowPsw"
              type="eye-invisible"
              id="demo_img"
            />
            <a-icon
              id="demo_img"
              v-if="showpass"
              @click="hideShowPsw"
              type="eye"
            />
          </div>
          <div v-if="submitLoading" class="tips-con">
            <img :src="require('@/assets/images/tisp.png')" alt="" />
            <h1>{{ tipsInfo }}</h1>
          </div>
        </div>
      </div>
      <div></div>
    </div>

    <p class="box">
      @2022-2023 重庆市农产品质量大数据 All rights reserved.
      裕狱中ICP备2022013057号
    </p>
  </div>
</template>

<script>
import loginApi from "@/request/api/user.js";
import storage from "@/storage/index.js";
import MutationType from "@/store/mutation-type.js";
import { spin } from "ant-design-vue";
import { mapGetters } from "vuex";

export default {
  name: "Login",
  data() {
    return {
      isPassingLock: false,
      userName: "",
      pwd: "",
      submitLoading: false,
      form: this.$form.createForm(this, { name: "login" }),
      password: "",
      tipsInfo: "",
      showPwdVis: false,
      loginErrorTimerf: null,
      Loading: false,
      /**默认密文 密码显示 */
      showpass: false,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  components: {
    "a-spin": spin,
  },
  mounted() {
    this.userName = this.userInfo.user.userName;
  },
  methods: {
    handleSubmit(e) {
      if (this.loginErrorTimerf) {
        clearTimeout(this.loginErrorTimerf);
      }
      this.loginErrorTimerf = setTimeout(() => {
        if (this.Loading) {
          return;
        }
        this.getLogin();
      }, 500);

      // e.preventDefault();
      // this.form.validateFields(async (err, values) => {

      // });
    },
    async getLogin() {
      // this.Loading = true;
      if (!this.userName || !this.password) {
        this.submitLoading = true;
        this.tipsInfo = "账号密码不能为空";
        return;
      }
      this.Loading = true;
      let params = {
        username: this.userName,
        password: this.password,
      };
      try {
        let res = await loginApi.login(params);
        this.Loading = false;
        let { token } = res;
        this.$store.commit(MutationType.SET_TOKEN_MUTATION, token);
        storage.setToken(token);
        this.$store.commit("setSuerParams", JSON.stringify(params));
        // storage.setSerParams(params);
        // this.$success({
        //   title: res.msg,
        // });
        this.$router.push({
          path: "/datav",
        });
      } catch (error) {
        console.log(error);
        this.Loading = false;
        this.$message.error(error.msg);
      }
    },
    hideShowPsw() {
      this.showpass = !this.showpass;
      var demoImg = document.getElementById("demo_img");
      var demoInput = document.getElementById("demo_input");
      if (demoInput.type == "password") {
        demoInput.type = "text";
        demoImg.src = "invisible.png";
      } else {
        demoInput.type = "password";
        demoImg.src = "visible.png";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
